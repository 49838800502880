@import '~antd/dist/antd.less';

:root {
    --primary: #4272d7;
    --text: #333333;
    --gray: #4F4F4F;
    --border: #E9E9EA;
    --white: #ffffff;
    --blue: #4272d7;
    --br-6: 6px;
    --br-12: 12px;
    --br-20: 20px;
}

body {
    overflow-x: hidden;
    font-family: "Poppins", "Arial", "Helvetica Neue", sans-serif;
    font-weight: 400;
    font-size: 14px;

}


.ant-layout {
    background: -webkit-gradient(linear, left bottom, right top, from(#fc2c77), to(#6c4079));
    background: -webkit-linear-gradient(bottom left, #fc2c77 0%, #6c4079 100%);
    background: -moz-linear-gradient(bottom left, #fc2c77 0%, #6c4079 100%);
    background: -o-linear-gradient(bottom left, #fc2c77 0%, #6c4079 100%);
    background: linear-gradient(to top right, #fc2c77 0%, #6c4079 100%);
}

.ant-layout-header {
    background: inherit;
}

.ant-layout-footer {
    background: inherit;
    padding: 0;
}

.text-center {
    text-align: center;
}

.page-wrapper {
    min-height: 100vh;
    background: -webkit-gradient(linear, left bottom, right top, from(#fc2c77), to(#6c4079));
    background: -webkit-linear-gradient(bottom left, #fc2c77 0%, #6c4079 100%);
    background: -moz-linear-gradient(bottom left, #fc2c77 0%, #6c4079 100%);
    background: -o-linear-gradient(bottom left, #fc2c77 0%, #6c4079 100%);
    background: linear-gradient(to top right, #fc2c77 0%, #6c4079 100%);
}


ul,
li {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

a,
a:hover {
    transition: all .3s;
}

.fs-mt-15 {
    margin-top: 15px;
}

.fs-mt-20 {
    margin-top: 20px;
}

.fs-mt-30 {
    margin-top: 30px;
}

.fs-mt-40 {
    margin-top: 40px;
}

.fs-mr-15 {
    margin-right: 15px;
}

.fs-mb-0 {
    margin-bottom: 0;
}

.fs-black {
    background-color: var(--text);
    border-color: var(--text);
}

.fs-container {
    max-width: 1320px;
    margin: 0 auto;
    padding-left: 15px;
    padding-right: 15px;
}

.ant-form-item-with-help {
    margin-bottom: 10px;
}

.ant-input {
    padding: 16px 16px 14px;
}

.ant-input-affix-wrapper {
    padding-top: 16px;
    padding-bottom: 14.20px;
}

.ant-input:focus,
.ant-input-focused,
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused,
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus,
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input-focused,
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:focus,
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector,
.ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input).ant-select-focused .ant-select-selector {
    box-shadow: none;
}

.ant-form-item-explain-error {
    font-size: 12px;
}

.ant-form-item-with-help .ant-form-item-explain {
    margin-top: 5px;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    font-weight: 400;
}

.ant-select-arrow {
    color: rgba(0, 0, 0, .8);
    right: 16px;
}

.ant-checkbox-inner {
    border-radius: 4px;
}

.ant-btn {
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 400;
    padding-left: 30px;
    padding-right: 30px;
    // padding-top: 14px !important;
    line-height: initial;
}

.fs-tabs {
    margin-top: 40px;
    margin-bottom: 40px;
}

.ant-tabs-top>.ant-tabs-nav::before {
    border-bottom: none;
}

.ant-tabs-nav-wrap {
    background-color: var(--text);
    overflow: hidden;
    border-radius: var(--br-12);
}

.ant-tabs-top>.ant-tabs-nav {
    margin-bottom: 30px;
}

.ant-tabs-tab {
    padding: 21px 25px 20px;
    text-transform: uppercase;
    color: var(--white);
    flex: 1;
    justify-content: center;
    cursor: pointer;
}

.ant-tabs-tab+.ant-tabs-tab {
    margin-left: 0;
}

.ant-tabs-tab.ant-tabs-tab-active {
    background-color: var(--primary);
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: var(--white);
    text-shadow: none;
}

.ant-tabs-ink-bar {
    background-color: transparent;
}

.ant-tabs>.ant-tabs-nav .ant-tabs-nav-list {
    flex: 0 0 100%;
}

.fs-about-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.fs-my-account h2.ant-typography {
    text-transform: uppercase;
    margin-bottom: 0;
}

.fs-about-list {
    margin-top: 30px;
}

.fs-about-list>li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    min-height: 60px;
    border: var(--border) 1px solid;
    border-radius: var(--br-12);
    margin-top: 10px;
}

.fs-about-title {
    flex: 1;
    font-weight: 500;
}

.fs-about-end {
    flex: 1;
}

.ant-tabs>.ant-tabs-nav .ant-tabs-nav-operations {
    display: none;
}

.fs-about .ant-form-item {
    margin-bottom: 0;
}

.fs-about .ant-select {
    width: 100%;
}

.fs-about .ant-select {
    width: 100%;
}

.fs-about textarea.ant-input {
    height: 80px;
    resize: none;
}

.fs-fs-myaccount-sec {
    margin-top: 10px;
}

.ant-rate-star-full svg {
    fill: var(--primary);
}

.fs-about .fs-upload {
    height: 45px;
    padding-top: 11.5px;
    padding-bottom: 11.5px;
}

.fs-round-btn-large.ant-btn {
    width: 50px;
    height: 50px;
    line-height: 64px;
    border-radius: 100%;
    padding: 0 !important;
    cursor: pointer;
}

.fs-round-btn-small.ant-btn {
    width: 35px;
    height: 35px;
    line-height: 41px;
    border-radius: 100%;
    padding: 0 !important;
}

.fs-absolute-btn {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // width: 100%;
    padding: 15px;
    transform: translateY(-20%);
    transition: all .3s;
    z-index: 1;
    opacity: 0;
}

.fs-float-btn:hover .fs-absolute-btn {
    transform: translateY(0);
    opacity: 1;
}

.fs-add-video .fs-about-title,
.fs-add-video .fs-about-end {
    flex: 0 0 40%;
}

.fs-helper-block {
    position: relative;
    padding-right: 60px;
    // border-bottom: var(--border) 1px solid;
    // padding-bottom: 15px;
}

.fs-helper-block:not(:first-child) {
    margin-top: 20px;
    // border-bottom: none;
    // padding-bottom: 0;
}

.fs-helper-block .fs-circle-btn {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
}

.fs-helper-block .fs-circle-btn .fs-round-btn-large.ant-btn {
    width: 40px;
    height: 40px;
    line-height: 47px;
}

.fs-sponsor-image {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 10px;
}

.fs-sponsor-image .ant-image {
    flex: 0 0 12.5%;
}

.fs-sponsor-image .ant-image-img {
    width: 100%;
    height: 80px;
    object-fit: cover;
}

.fs-helper-zero-padd {
    padding-right: 0;
}

.fs-helper-zero-padd div.ant-typography {
    margin-bottom: 0;
}

.fs-about-list>li.fs-align-items-top {
    align-items: flex-start;
}

.fs-about-list>li.fs-align-items-top .fs-about-title {
    padding-top: 3px;
}

.fs-slick-arrows .slick-prev,
.fs-slick-arrows .slick-next {
    background-color: var(--text);
    color: var(--white);
    width: 40px;
    height: 40px;
    border-radius: 50px;
    z-index: 1;
    transition: all .3s;
}

.fs-slick-arrows .slick-prev {
    left: -70px;
}

.fs-slick-arrows .slick-next {
    right: -70px;
}

.fs-slick-arrows .slick-prev:before,
.fs-slick-arrows .slick-next:before {
    content: '';
    opacity: 1;
}

.fs-slick-arrows svg {
    position: absolute;
    top: 48%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.fs-slick-arrows .slick-prev:hover,
.fs-slick-arrows .slick-next:hover {
    background-color: var(--primary);
}

.fs-gallerys {
    margin-top: 15px;
}

.fs-gallerys .ant-image {
    overflow: hidden;
    border-radius: var(--br-12);
}

.fs-gallery-img {
    position: relative;
    margin-top: 25px;
}

.fs-gallery-img .ant-image-img {
    height: 420px;
    object-fit: cover;
}

.fs-upload-gallery .ant-btn {
    margin-bottom: 20px;
}

.fs-upload-gallery .ant-btn.ant-upload-list-item-card-actions-btn {
    margin-bottom: 0;
}

.fs-gallery .ant-upload-list-item {
    margin-top: 0;
    margin-bottom: 24px;
}

.fs-gallery textarea.ant-input {
    height: 150px;
}

.ant-modal-close-x {
    line-height: 66px;
}

.ant-modal-footer {
    display: none;
}

.edit-header-form textarea.ant-input {
    height: 100px;
}

.fs-section {
    padding-top: 60px;
    padding-bottom: 60px;
}

.fs-block-header {
    text-align: center;
}

.fs-block-header .ant-image-img {
    width: 85%;
    margin: 0 auto;
}

.fs-block-header h1.ant-typography {
    font-size: 36px;
    text-transform: uppercase;
    font-weight: 600;
    margin-top: -60px;
}

.fs-slick-slider {
    margin-top: 70px;
}

.fs-slick-slider .slick-list {
    margin-left: -30px;
    margin-right: -30px;
}

.fs-slick-slider .slick-slide {
    padding-left: 30px;
    padding-right: 30px;
}

.fs-slick-slider {
    padding-left: 70px;
    padding-right: 70px;
}

.fs-slick-slider .slick-prev {
    left: 0px;
}

.fs-slick-slider .slick-next {
    right: 0px;
}

.fs-tab-list {
    display: flex;
    align-items: center;
}

.fs-tab-list li {
    flex: 1;
}

.fs-tab-list li a {
    background-color: var(--primary);
    display: block;
    color: var(--white);
    text-align: center;
    padding: 25px 20px;
    font-size: 22px;
    text-transform: uppercase;
    border-right: 1px solid white;
}

.fs-tab-list li a:hover {
    background-color: var(--text);
}

.fs-fitness {
    padding: 40px 0;
}

.fs-right {
    text-align: right;
}

.fs-pros {
    margin-top: 40px;
}

.fs-pros img {
    width: 70px;
    height: 70px;
    border-radius: var(--br-12);
    object-fit: cover;
}

.fs-pros .ant-table-thead>tr>th {
    border: none;
    background-color: #F1F1F1;
}

.fs-pros .ant-table table {
    border-radius: 0;
    border-collapse: separate;
    border-spacing: 0 5px;
}

.fs-pros .ant-table-container table>thead>tr:first-child th:first-child {
    border-bottom-left-radius: var(--br-12);
}

.fs-pros .ant-table-container table>thead>tr:first-child th:last-child {
    border-bottom-right-radius: var(--br-12);
}

.fs-pros .ant-table-thead>tr>th {
    padding: 18px 15px;
}

.fs-pros .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    display: none;
}

.fs-pros .ant-table-tbody .ant-table-row {
    position: relative;
    top: 5px;
}

.fs-pros .ant-table-tbody>tr td:first-child {
    width: 15%;
}

.fs-pros .ant-table-tbody>tr>td {
    border: var(--border) 1px solid;
    padding: 10px 10px;
}

.fs-pros .ant-table-tbody>tr>td:first-child {
    border-right: none;
}

.fs-pros .ant-table-tbody>tr>td:nth-child(2),
.fs-pros .ant-table-tbody>tr>td:nth-child(3),
.fs-pros .ant-table-tbody>tr>td:nth-child(4) {
    border-right: none;
    border-left: none;
}

.fs-center .ant-table-tbody>tr>td:nth-child(5) {
    border-right: none;
    border-left: none;
}

.fs-pros .ant-table-tbody>tr>td:last-child {
    border-left: none;
}

.fs-pros .ant-table-tbody>tr td:first-child {
    border-top-left-radius: var(--br-12);
    border-bottom-left-radius: var(--br-12);
}

.fs-pros .ant-table-tbody>tr td:last-child {
    border-top-right-radius: var(--br-12);
    border-bottom-right-radius: var(--br-12);
}

.fs-pros .ant-table-thead>tr>th:nth-child(1),
.fs-pros .ant-table-tbody>tr>td:nth-child(1) {
    width: 10%;
}

.fs-pros .ant-table-thead>tr>th,
.fs-pros .ant-table-tbody>tr>td {
    width: 20%;
}

.fs-center .ant-table-thead>tr>th,
.fs-center .ant-table-tbody>tr>td {
    width: 15%;
}

.fs-about-img {
    padding-right: 5%;
}

.ant-popover-buttons {
    text-align: left;
    display: flex;
    justify-content: space-between;
}

.ant-popover-buttons .ant-btn {
    width: 100%;
    padding: 15px 20px;
    line-height: 2px !important;
}

// Blog
.fs-blog-list {
    display: flex;
    align-items: center;
}

.fs-blog-list li.fs-list-dot {
    background-color: var(--text);
    width: 4px;
    height: 4px;
    border-radius: 10px;
    opacity: .8;
    margin-left: 15px;
    margin-right: 15px;
}

@media(min-width: 1440px) {
    .fs-section {
        padding-top: 100px;
        padding-bottom: 100px;
    }

    .fs-block-header .ant-image-img {
        width: 100%;
    }

    .fs-block-header h1.ant-typography {
        font-size: 46px;
        margin-top: -70px;
    }

    .fs-slick-slider {
        margin-top: 90px;
    }

    .fs-fitness {
        padding: 60px 0;
    }

    .fs-pros {
        margin-top: 60px;
    }
}

@media(max-width: 1191px) {
    .fs-slick-slider .slick-slide {
        padding-left: 10px;
        padding-right: 10px;
    }

    .fs-tab-list li a {
        padding: 22px 20px;
        font-size: 18px;
    }

    .fs-sponsor-image {
        grid-template-columns: repeat(6, 1fr);
    }
}

@media(max-width: 991px) {

    .fs-about-title,
    .fs-about-end {
        flex: 1;
    }

    .fs-absolute-btn {
        opacity: 1;
    }

    .fs-contact-form {
        padding-left: 0;
        margin-top: 40px;
    }

    .fs-block-header .ant-image-img {
        width: 80%;
    }

    .fs-block-header h1.ant-typography {
        font-size: 30px;
        margin-top: -55px;
    }

    .fs-slick-slider {
        margin-top: 50px;
    }

    .fs-fitness {
        padding: 30px 0;
    }

    .fs-pros {
        margin-top: 30px;
    }

    .fs-sponsor-image {
        grid-template-columns: repeat(5, 1fr);
    }

}

@media(max-width: 767px) {
    .fs-about-list>li {
        flex-direction: column;
        align-items: flex-start;
        padding-left: 15px;
        padding-right: 15px;
    }

    .fs-about-title {
        margin-top: 4px;
        margin-bottom: 5px;
    }

    .fs-about .fs-about-end {
        margin-top: 5px;
        width: 100%;
    }

    .fs-my-account {
        margin-top: 0 !important;
    }

    .react-player__preview svg {
        width: 50px;
        height: 50px;
    }

    .fs-add-video .fs-about-title {
        margin-top: 0;
    }

    .fs-add-video .fs-about-end {
        margin-top: 15px;
    }

    .fs-add-video .fs-about-title,
    .fs-add-video .fs-about-end {
        flex: 0 0 100%;
        width: 100%;
    }

    .slick-dots {
        bottom: -12px !important;
    }

    .slick-dots li button:before {
        opacity: 1;
    }

    .slick-dots li.slick-active button:before {
        color: var(--primary);
        opacity: 1;
    }

    .ant-btn {
        height: 44px;
        padding: 13px 20px;
        padding-top: 13px !important;
        // line-height: 42px !important;
    }

    .fs-section {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .fs-block-header .ant-image-img {
        width: 65%;
    }

    .fs-block-header h1.ant-typography {
        margin-top: -45px;
    }

    .fs-tab-list li a {
        padding: 18px 20px;
        font-size: 16px;
    }

    .fs-about-img {
        padding-right: 0;
    }

    .fs-blog-page .fs-tabs {
        margin-top: 25px;
    }

    .ant-popover-buttons .ant-btn {
        height: 32px;
        line-height: 4px !important;
    }

    .fs-sponsor-image {
        grid-template-columns: repeat(6, 1fr);
    }

}

.propaneMobile {
    width: 80%;
    background: #cccccc69;
    margin: 0 auto;
    border-radius: 15px;
    padding: 15px;
    border: 1px solid #ccc;
    max-height: 70vh;
    overflow-y: auto;
}

@media(max-width: 576px) {
    .page-wrapper {
        background: url('./assets/images/propane.jpg') top left/cover;
    }

    

    .headImagePadding {
        padding-bottom: 15px;
    }

    .fs-round-btn-large.ant-btn {
        width: 40px;
        height: 40px;
        line-height: 47px;
        border-radius: 100%;
        padding: 0;
    }

    .fs-round-btn-large.ant-btn svg {
        width: 18px;
        height: 18px;
    }

    .fs-my-account h2.ant-typography {
        font-size: 22px;
    }

    .ant-tabs-tab {
        padding: 18px 25px 17px;
    }

    .fs-about-list {
        margin-top: 20px;
    }

    .fs-gallerys {
        margin-top: 0;
    }

    .fs-gallery-img {
        margin-top: 15px;
    }

    a.ant-btn {
        line-height: 42px !important;
    }

    .fs-block-header h1.ant-typography {
        font-size: 26px;
        margin-top: -40px;
    }

    .fs-tab-list li a {
        padding: 16px 5px;
        font-size: 12px;
    }

    .fs-sponsor-image {
        grid-template-columns: repeat(3, 1fr);
    }

    .fs-sponsor-image .ant-image-img {
        height: 102px;
    }

}

.vcardHeight {
    height: calc(100vh - 180px)
}


.propane .ant-form-item-label label {
    color: #fff;
}

.propane .ant-checkbox-wrapper {
    color: #fff;
}
@body-background: #fff;@layout-body-background: #fff;@layout-header-background: #ffffff;@primary-color: #FF1A1A;@border-radius-base: 12px;@line-height-base: 1.3;@font-family: 'Poppins', 'Arial', 'Helvetica Neue', sans-serif;@heading-color: #333333;@text-color: #333333;@text-color-secondary: #4F4F4F;@border-color-base: #E9E9EA;@heading-2-size: 26px;@heading-3-size: 22px;@heading-4-size: 18px;@heading-5-size: 16px;@typography-title-font-weight: 500;@input-height-base: 49px;@input-placeholder-color: #989898;@input-border-color: #E9E9EA;@input-bg: #ffffff;@input-color: #333333;@input-padding-horizontal: 16px;@input-hover-border-color: #E9E9EA;@error-color: #FF1A1A;@checkbox-size: 20px;@btn-font-weight: 500;@btn-border-radius-base: 10px;@btn-height-base: 49px;@btn-shadow: none;@menu-dark-color: #ffffff;@menu-dark-bg: #000000;@menu-dark-item-active-bg: #212121;@menu-dark-selected-item-icon-color: #87FDA5;@menu-dark-selected-item-text-color: #87FDA5;@page-header-back-color: #ffffff;@dropdown-vertical-padding: 10px;@table-header-bg: #F4F6F7;@table-header-color: #2C302E;@table-header-sort-bg: #F4F6F7;@table-padding-vertical: 14px;@table-padding-horizontal: 20px;@table-border-color: #E5E9EF;