.cardCenter {
    width: 87%;
    text-align: center;
    margin: 0 auto !important;
    margin-top: 10% ;
    margin-bottom: 5% ;
}

.ant-card-body {
    padding: 57px 65px !important;
    padding-bottom: 65px !important;
}



.title {
    font-size: 23px;
    color: #fff;
    font-weight: 400;
    margin-bottom: 40px;
    text-align: center;
}

.btn-submit {
    background: #fc2c77 !important;
    width: 100%;
    margin-top: 15px;
    color: #fff !important;
    font-weight: 400 !important;
}

.btn-submit:hover {
    border: 2px solid #fc2c77 !important;
}

.imag-cls {
    background: url('../../assets/images/propane.jpg') top left/cover;
    height: 100vh
}


@media(max-width: 576px) {
    .cardCenter {
        width: 80%;
    }

    .title {
        font-size: 17px;
    }

    .ant-card-body {
        padding: 35px !important;

    }
}

@media(max-width: 767px) {}