.fs-footer-copyright {
    background-color: #fff;
    color: black;
    text-align: center;
    padding: 25px 20px;
}

.headImage {
    padding-top: 45px;
    width: 80% !important;
}

.headImagePadding{
    padding-bottom: 45px;

}


@media(max-width: 1100px) {}

@media(max-width: 767px) {}