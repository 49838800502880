.ant-checkbox-group{
    display: grid !important;
}
.formClass{
    max-height: 330px;
    overflow-y: auto;
    padding: 25px;
    border: 1px solid #fff;
    border-radius: 15px;
}

.propaneMobile::-webkit-scrollbar-track {
    border: 1px solid #cccc;
    padding: 2px 0;
    background-color: #fff;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
  }
  
  .propaneMobile::-webkit-scrollbar {
    width: 10px;
  }
  
  .propaneMobile::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 6px #cccc;
    background-color: #cccc;
    border: 1px solid #ccc;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
  }