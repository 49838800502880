.cardDashboardCenter {
    width: 80% !important;
    margin-top: 3% !important
}

.cardDashboardCenter .ant-card-body {
    padding: 20px !important;
}

.cardDashboardCenter .ant-table-container {
    padding: 10px !important;
}

.cardBackGround {
    background: #ffff;
}

.mb-0 {
    margin-bottom: 0px !important;
}

.trashClass {
    cursor: pointer;
}

.trashClass :hover {
    color: #FF1A1A;
}